<template>
  <section class="mx-auto max-w-md p-5">
    <div class="space-y-3 rounded-2xl bg-white p-7">
      <div class="flex items-center">
        <h1
          class="bg-logo mb-4 h-12 flex-grow bg-contain bg-no-repeat"
          :style="{ backgroundImage: `url(${logoUrl})` }"
        >
          <span class="invisible">VolgPlan</span>
        </h1>
        <lock-closed-icon class="-mt-4 ml-auto mr-2 w-8 text-teal-500" />
      </div>

      <h2 class="text-lg font-bold">Beveiligde omgeving</h2>

      <p>Om toegang te krijgen tot VolgPlan heb je een account nodig.</p>

      <p class="space-x-2">
        <a :href="loginUrl" class="button" autofocus>Inloggen</a>

        <span>
          of
          <a :href="registerUrl" class="underline">registreren</a>
        </span>
      </p>

      <p
        class="!mt-4 rounded-md bg-neutral-100 px-3 py-2 text-sm [&>a]:underline"
      >
        Lukt het niet om toegang te krijgen?<br />Neem contact op met
        <a href="mailto:support@volgplan.nl">de Helpdesk</a>
      </p>
    </div>
  </section>
</template>

<script setup lang="ts">
  import logoUrl from '../assets/logo.svg'
  import { LockClosedIcon } from '@heroicons/vue/24/solid'

  const loginUrl = import.meta.env.VITE_APP_LOGIN_URL
  const registerUrl = import.meta.env.VITE_APP_REGISTER_URL
</script>
