<template>
  <modal @dismiss="emit('dismiss')">
    <template #title>Nieuws</template>

    <a
      @click="emit('dismiss')"
      class="absolute right-5 top-4 flex cursor-pointer gap-1 rounded-md border px-2 py-1 text-sm hover:bg-neutral-100"
    >
      <span>Sluiten</span>
      <XMarkIcon class="w-4" />
    </a>

    <div class="space-y-4">
      <div
        v-for="item in items"
        :key="`item-${item.id}`"
        class="rounded-lg border-2 bg-neutral-100 p-4"
        :class="{ 'border-pink-700': item.id > lastRead }"
      >
        <h4 class="mb-2 flex items-start font-bold">
          <span>{{ item.title }}</span>
          <span
            v-if="item.id > lastRead"
            class="ml-auto rounded-md bg-pink-700 px-2 py-1 text-xs text-white"
            >Nieuw</span
          >
        </h4>
        <div class="wrapper" v-html="item.body" />
      </div>
    </div>
  </modal>
</template>

<script setup lang="ts">
  import { XMarkIcon } from '@heroicons/vue/24/outline'
  import Modal from './Modal.vue'
  import { PropType } from 'vue'

  const emit = defineEmits(['dismiss'])

  defineProps({
    items: {
      type: Array as PropType<NewsItem[]>,
      required: true,
    },

    lastRead: {
      type: Number,
      required: true,
    },
  })
</script>

<style lang="postcss" scoped>
  .wrapper :deep(p) {
    @apply mb-3 last:mb-0;
  }

  .wrapper :deep(a) {
    @apply text-teal-500 underline;
  }

  .wrapper :deep(ol),
  .wrapper :deep(ul) {
    @apply mb-3 ml-6;
  }

  .wrapper :deep(ul) {
    @apply list-disc;
  }

  .wrapper :deep(ol) {
    @apply list-decimal;
  }
</style>
