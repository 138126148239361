<template>
  <router-view v-if="['dashboard', 'public'].includes(appState)" />

  <volgplan
    v-else-if="appState === 'authorized'"
    :me="data.me"
    @set:flash="flash = $event"
    @update:location="loadUser"
    @logout="appState = 'unauthorized'"
  />

  <unauthorized v-else />

  <flash v-if="flash" :flash="flash" @reset:flash="flash = null" />
</template>

<script setup lang="ts">
  import { useQuery } from 'villus'
  import Volgplan from './Volgplan.vue'
  import Unauthorized from './views/Unauthorized.vue'
  import Flash from './components/Flash.vue'
  import query from './graphql/Me.graphql'

  import { useHead } from '@vueuse/head'
  import {
    RouteLocationNormalizedLoaded,
    useRoute,
    useRouter,
  } from 'vue-router'
  import { onMounted, onUnmounted, ref, watch } from 'vue'

  const appState = ref('unauthorized')
  const route = useRoute()
  const router = useRouter()
  const flash = ref<Flash | null>(null)

  const {
    data,
    isDone,
    error,
    execute: loadUser,
  } = useQuery({ query, fetchOnMount: false })

  const setState = (route: RouteLocationNormalizedLoaded) => {
    if (route.name === 'Dashboard') {
      appState.value = 'dashboard'
    } else if (route.name === 'PublicPage') {
      appState.value = 'public'
    } else if (!isDone.value) {
      loadUser()
    }
  }

  setState(route)
  watch(router.currentRoute, setState)

  watch(isDone, () => {
    if (!error.value) {
      appState.value = 'authorized'
    }
  })

  // Auth Status-check

  const authInterval = ref(0)

  const checkIfAuthorized = async () => {
    const resp = await fetch(import.meta.env.VITE_APP_STATUS_URL, {
      credentials: 'include',
    })

    const { authorized } = await resp.json()

    if (!authorized && appState.value === 'authorized') {
      appState.value = 'unauthorized'
    }
  }

  onMounted(() => {
    authInterval.value = window.setInterval(checkIfAuthorized, 15000)
  })

  onUnmounted(() => window.clearInterval(authInterval.value))

  window.errorHandler = (message: string, status: number) => {
    flash.value = { message, kind: 'alert' }

    if (status === 401 && appState.value === 'authorized') {
      appState.value = 'unauthorized'
    }
  }

  useHead({
    titleTemplate: (title?: string) => {
      return title ? `${title} – VolgPlan` : 'VolgPlan'
    },
  })
</script>

<style lang="postcss">
  body {
    @apply bg-gray-100 font-sans antialiased;
  }
</style>
