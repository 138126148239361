<template>
  <div
    class="fixed left-0 top-0 z-10 !mt-0 h-full w-full overflow-auto bg-neutral-500/70 backdrop-blur-sm"
    @mouseup.self="emit('dismiss')"
  >
    <div
      class="relative mx-auto my-4 w-full max-w-lg bg-white px-5 py-4 shadow-xl sm:my-10 sm:rounded-2xl"
    >
      <h2 class="mb-4">
        <dot-title><slot name="title" /></dot-title>
      </h2>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  import DotTitle from './DotTitle.vue'
  const emit = defineEmits(['dismiss'])
</script>
