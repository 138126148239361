<template>
  <span
    class="inline-block w-6 rounded-full bg-orange-600 py-1 text-center font-mono text-xs text-white"
    :title="user.name"
    >{{ initials }}</span
  >
</template>

<script setup lang="ts">
  import { computed, PropType } from 'vue'

  const props = defineProps({
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  })

  const initials = computed(() => {
    const name = props.user.name,
      caps = name.match(/[A-Z]/g) || ['?'],
      initials = [caps[0]]

    if (caps.length > 1) {
      initials.push(caps[caps.length - 1])
    }

    return initials.join('')
  })
</script>
