<template>
  <div class="h-14 bg-pink-900">
    <div class="mx-auto flex h-full max-w-6xl items-center px-6">
      <template v-if="info">
        <router-link
          v-if="info.route"
          :to="info.route"
          class="mr-4 flex rounded-md bg-pink-800 py-2 pl-2 pr-4 text-xs font-bold text-white before:mr-1"
          ><arrow-left-icon class="mr-1 w-4" />Terug</router-link
        >
        <dot-title class="hidden text-white sm:flex">{{
          info.title
        }}</dot-title>

        <button
          v-if="info.submitButton"
          class="ml-auto py-1"
          @click.prevent="emit('submit:form')"
        >
          Opslaan
        </button>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, reactive, watch, PropType } from 'vue'
  import { useRoute } from 'vue-router'

  import DotTitle from '../components/DotTitle.vue'
  import { ArrowLeftIcon } from '@heroicons/vue/24/outline'

  const emit = defineEmits(['submit:form'])

  const props = defineProps({
    title: {
      type: String as PropType<string | null>,
      default: () => null,
    },
  })

  const route = useRoute()

  const kind = computed(() => route.params.kind)
  const groupId = computed(() => route.params.groupId)
  const studentId = computed(() => route.params.studentId)

  const data = reactive<{ [key: string]: { [key: string]: any } }>({
    EditPlan: {
      title: 'Persoonlijk plan',
      route: {
        name: 'Plans',
        params: { groupId, studentId },
      },
      submitButton: true,
    },

    Group: {
      title: 'Groep',
      route: { name: 'Groups' },
    },

    GroupPost: {
      title: 'Berichten',
      route: { name: 'Groups' },
    },

    GroupPosts: {
      title: 'Berichten',
      route: { name: 'Groups' },
    },

    GroupSchedules: {
      title: 'Weekplanningen',
      route: { name: 'Groups' },
    },

    GroupStudents: {
      title: 'Leerlingen',
      route: { name: 'Groups' },
    },

    GroupStudent: {
      title: 'Leerlingen',
      route: { name: 'Groups' },
    },

    GroupTeachers: {
      title: 'Leerkrachten',
      route: { name: 'Groups' },
    },

    GroupSettings: {
      title: 'Instellingen',
      route: { name: 'Groups' },
    },

    Groups: { title: 'Groepen' },

    Location: {
      title: 'School',
      route: { name: 'Locations' },
    },

    LocationCourses: {
      title: 'Vakken',
      route: { name: 'Locations' },
    },

    LocationPersonnel: {
      title: 'Personeel',
      route: { name: 'Locations' },
    },

    LocationPlanCategories: {
      title: 'Persoonlijke plannen',
      route: { name: 'Locations' },
    },

    LocationEdexImport: {
      title: 'EDEX importeren',
      route: { name: 'Locations' },
    },

    LocationDevices: {
      title: 'Apparaten koppelen',
      route: { name: 'Locations' },
    },

    Locations: { title: 'Scholen' },

    ManageUser: {
      title: 'Personeel',
      route: { name: 'LocationPersonnel' },
    },

    ManageCourse: {
      title: 'Vakken',
      route: { name: 'LocationCourses' },
    },

    NewPlan: {
      title: 'Persoonlijk plan',
      route: { name: 'Plans', params: { groupId, studentId } },
      submitButton: true,
    },

    EditPost: {
      title: 'Bewerk bericht',
      route: { name: 'GroupPosts', params: { groupId, kind } },
    },

    NewPost: {
      title: 'Nieuw bericht',
      route: { name: 'GroupPosts', params: { groupId, kind } },
    },

    EditProgram: {
      title: 'Methode',
      route: { name: 'Programs' },
    },

    NewProgram: {
      title: 'Nieuwe methode',
      route: { name: 'Programs' },
    },

    Plans: {
      title: 'Persoonlijke plannen',
      route: { name: 'GroupStudents', params: { groupId } },
    },

    Programs: { title: 'Lesmethoden' },

    ScheduleVariant: {
      title: 'Weekplanning',
      route: { name: 'Group', params: { groupId } },
      submitButton: true,
    },

    NewScheduleVariant: {
      title: 'Weekplanning',
      route: { name: 'Group', params: { groupId } },
      submitButton: true,
    },

    empty: { title: '' },
  })

  const routeName = ref(route.name)

  watch(route, () => (routeName.value = route.name))

  const info = computed(() => {
    const key = routeName.value?.toString()
    const infoData = data[key || 'empty'] || data['empty']

    if (props.title) {
      infoData.title = props.title
    }

    return infoData
  })
</script>
