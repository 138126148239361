import { createApp } from 'vue'
import { createClient, defaultPlugins } from 'villus'
import { multipartPlugin, cookiePlugin, errorPlugin } from './mixins/villus'
import { createHead } from '@vueuse/head'
import * as Sentry from '@sentry/vue'

import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import objectSupport from 'dayjs/plugin/objectSupport'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'
import isLeapYear from 'dayjs/plugin/isLeapYear'

import './index.css'
import router from './router'

import App from './App.vue'

// @ts-ignore
Date.prototype.getWeek = function () {
  var onejan = new Date(this.getFullYear(), 0, 1)
  // @ts-ignore
  return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7)
}

dayjs.extend(weekOfYear)
dayjs.extend(utc)
dayjs.extend(isoWeek)
dayjs.extend(isoWeeksInYear)
dayjs.extend(isLeapYear)
dayjs.extend(objectSupport)

const client = createClient({
  url: import.meta.env.VITE_APP_API_URL,
  cachePolicy: 'cache-and-network',
  use: [cookiePlugin(), multipartPlugin(), errorPlugin(), ...defaultPlugins()],
})

const app = createApp(App)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: __APP_VERSION__,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.25,
  tracePropagationTargets: ['localhost', /^https:\/\/.+\.volgplan\.nl/],
  replaysSessionSampleRate: 0.15,
  replaysOnErrorSampleRate: 0.75,
})

const head = createHead()

app.use(router).use(client).use(head).provide('dayjs', dayjs).mount('#app')
