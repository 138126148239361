import { definePlugin } from 'villus'

// @ts-ignore
import extractFiles from 'extract-files/extractFiles.mjs'
// @ts-ignore
import isExtractableFile from 'extract-files/isExtractableFile.mjs'

function cookiePlugin() {
  return definePlugin(function cookiesPlugin(context) {
    const { opContext } = context
    opContext.credentials = 'include'
  })
}

function errorPlugin() {
  return definePlugin(function errorsPlugin({ afterQuery }) {
    afterQuery(({ error }, { response }) => {
      if (response?.status === 401) {
        window.errorHandler('Log eerst in voor je verder kunt gaan.', 401)
      }

      if (error) {
        console.error(error)
      }
    })
  })
}

function authPlugin(token: string) {
  return definePlugin(({ opContext }) => {
    opContext.headers.Authorization = `Bearer ${token}`
  })
}

function multipartPlugin() {
  return definePlugin(function multipartPlugin(context) {
    const { opContext, operation } = context

    const { files, clone: variables } = extractFiles(
      {
        ...(operation?.variables || {}),
      },
      isExtractableFile,
    )

    if (!files.size) {
      return
    }

    if (opContext.headers['content-type'] === 'application/json') {
      delete opContext.headers['content-type']
    }

    const body = new FormData()
    // @ts-ignore
    body.append('query', operation.query.loc.source.body)

    const myFiles = {}

    // @ts-ignore
    const map = { ...variables }
    // @ts-ignore
    files.forEach((paths, file) => {
      // @ts-ignore
      paths.forEach((path) => {
        const [name, idx] = path.split('.')
        if (idx) {
          map[name][parseInt(idx)] = path
        } else {
          delete map[path]
          map[path] = path
        }
        // @ts-ignore
        myFiles[path] = file as File
      })
    })

    body.append('variables', JSON.stringify(map))

    Object.keys(myFiles).forEach((key) => {
      // @ts-ignore
      const file = myFiles[key]
      body.append(key, file, file.name)
    })

    opContext.body = body
  })
}

export { authPlugin, multipartPlugin, cookiePlugin, errorPlugin }
