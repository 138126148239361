<template>
  <div
    class="fixed right-0 top-0 z-30 mr-4 mt-5 flex cursor-pointer items-center gap-2 rounded-md bg-black px-4 py-2 text-white opacity-80 shadow-md hover:opacity-100"
    :class="{ 'bg-red-600 text-white': flash.kind === 'alert' }"
    @click="resetFlash"
  >
    <bell-alert-icon v-if="flash.kind === 'alert'" class="w-5" />
    <information-circle-icon v-else class="w-5" />
    <span>{{ flash.message }}</span>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, PropType } from 'vue'
  import {
    InformationCircleIcon,
    BellAlertIcon,
  } from '@heroicons/vue/24/outline'

  const emit = defineEmits(['reset:flash'])
  const resetFlash = () => emit('reset:flash')

  onMounted(() => {
    setTimeout(resetFlash, 2500)
  })

  defineProps({
    flash: {
      type: Object as PropType<Flash>,
      required: true,
    },
  })
</script>
