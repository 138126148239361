<template>
  <div v-if="currentLocation">
    <main-header
      :current-location="currentLocation"
      :me="me"
      :location-count="locationCount"
      :back-bar-title="backBarTitle"
      @set:current-location="currentLocation = $event"
      @submit:form="submitForm = true"
    />

    <main class="md:px-6">
      <div
        class="relative mx-auto mb-10 max-w-6xl bg-white shadow-xl md:mt-40 md:rounded-lg"
      >
        <router-view
          :current-location="currentLocation"
          :me="me"
          :submit-form="submitForm"
          @set:back-bar-title="backBarTitle = $event"
          @set:flash="emit('set:flash', $event)"
          @update:location="emit('update:location')"
        />
      </div>

      <contact-widget />
    </main>
  </div>
</template>

<script setup lang="ts">
  import Cookies from 'js-cookie'
  import * as Sentry from '@sentry/vue'

  import { computed, nextTick, onMounted, ref, watch } from 'vue'
  import { useRouter } from 'vue-router'

  import MainHeader from './components/MainHeader.vue'
  import ContactWidget from './components/ContactWidget.vue'

  const emit = defineEmits(['update:location', 'logout', 'set:flash'])
  const router = useRouter()

  const backBarTitle = ref<null | string>(null)
  const submitForm = ref(false)

  const props = defineProps<{ me: User }>()

  const sentryUser = {
    id: props.me.id,
    name: props.me.name,
  } as SentryUser

  if (props.me.email) {
    sentryUser.email = props.me.email
  }

  Sentry.setUser(sentryUser)

  watch(router.currentRoute, () => (backBarTitle.value = null))
  watch(submitForm, () => nextTick(() => (submitForm.value = false)))

  const currentLocation = ref<null | TenantLocation>(null)
  const savedLocationId = ref(Cookies.get('currentLocationId'))

  const locationCount = computed(() => {
    if (props.me) {
      const locationIds = (props.me.roles || []).map(
        (role: Role) => role.location.id,
      )
      const uniqueLocationIds = new Set(locationIds)
      return uniqueLocationIds.size
    } else {
      return 0
    }
  })

  const setCurrentLocation = () => {
    const savedRole = (props.me.roles || []).find((role: Role) => {
      return role.location.id === savedLocationId.value
    })

    if (savedRole) {
      currentLocation.value = savedRole.location
    } else {
      currentLocation.value = (props.me.roles || [])[0]?.location
    }

    if (!currentLocation.value) {
      emit('logout')
    }
  }

  onMounted(setCurrentLocation)

  watch(currentLocation, (newLocation) => {
    if (
      locationCount.value > 1 &&
      newLocation &&
      newLocation.id !== savedLocationId.value
    ) {
      savedLocationId.value = newLocation.id
      Cookies.set('currentLocationId', savedLocationId.value, { expires: 7 })
      router.push({ name: 'Groups' })
    }
  })

  watch(props, setCurrentLocation)
</script>

<style lang="postcss">
  table {
    border-collapse: collapse;
    min-width: 750px;
    table-layout: fixed;
    width: 100%;
  }

  th {
    text-align: left;
  }

  td,
  th {
    border-top: #eee 1px solid;
    padding: 15px 0;
    vertical-align: top;
  }

  input[type='text'],
  input[type='email'],
  input[type='date'],
  input[type='number'],
  select,
  textarea,
  .textarea {
    @apply inline-block w-full appearance-none rounded-md border-2 border-gray-100 bg-gray-100 px-3 py-2 text-[16px] outline-none focus:border-gray-400;
  }

  textarea {
    @apply w-full max-w-xl;
  }

  button,
  .button {
    @apply inline-block cursor-pointer rounded-md bg-teal-500 px-4 py-2 !text-white !no-underline;
  }

  button.add {
    @apply bg-pink-800 py-1;
  }

  button.close {
    @apply bg-gray-300 text-black;
  }
</style>
