import { createRouter, createWebHistory } from 'vue-router'
import Groups from '../views/Groups.vue'

const routes = [
  {
    path: '/',
    name: 'Root',
    redirect: { name: 'Groups' },
  },
  {
    path: '/dashboard/:token',
    name: 'Dashboard',
    component: () => import('../views/DashboardLogin.vue'),
    props: true,
  },
  {
    path: '/publiek/:locationToken/:studentToken',
    name: 'PublicPage',
    component: () => import('../views/PublicPage.vue'),
    props: true,
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
  },
  {
    path: '/groups/:groupId',
    name: 'Group',
    redirect: { name: 'GroupSchedules' },
    component: () => import('../views/Group.vue'),

    children: [
      {
        path: 'schedules',
        name: 'GroupSchedules',
        component: () => import('../views/GroupSchedules.vue'),
      },
      {
        path: 'posts/:kind',
        name: 'GroupPosts',
        component: () => import('../views/GroupPosts.vue'),
      },
      {
        path: 'posts/:kind/:postId',
        name: 'GroupPost',
        component: () => import('../views/GroupPosts.vue'),
      },
      {
        path: 'teachers',
        name: 'GroupTeachers',
        component: () => import('../views/GroupTeachers.vue'),
      },
      {
        path: 'students',
        name: 'GroupStudents',
        component: () => import('../views/GroupStudents.vue'),
        children: [
          {
            path: ':studentId',
            name: 'GroupStudent',
            component: () => import('../views/GroupStudent.vue'),
            props: true,
          }
        ]
      },
      {
        path: 'settings',
        name: 'GroupSettings',
        component: () => import('../views/GroupSettings.vue'),
      },
    ],
  },
  {
    path: '/groups/:groupId/students/:studentId/plans',
    name: 'Plans',
    component: () => import('../views/Plans.vue'),
  },
  {
    path: '/groups/:groupId/students/:studentId/plans/new',
    name: 'NewPlan',
    component: () => import('../views/Plan.vue'),
    props: true,
  },
  {
    path: '/groups/:groupId/students/:studentId/plans/:planId',
    name: 'EditPlan',
    component: () => import('../views/Plan.vue'),
    props: true
  },
  {
    path: '/programs',
    name: 'Programs',
    component: () => import('../views/Programs.vue'),
  },
  {
    path: '/programs/new',
    name: 'NewProgram',
    component: () => import('../views/Program.vue'),
  },
  {
    path: '/programs/:programId',
    name: 'EditProgram',
    component: () => import('../views/Program.vue'),
    props: true
  },
  {
    path: '/groups/:groupId/schedules/new',
    name: 'NewSchedule',
    props: true,
    component: () => import('../views/Schedule.vue'),
    children: [
      {
        path: 'variants/:variantIdx',
        name: 'NewScheduleVariant',
        component: () => import('../views/ScheduleVariant.vue'),
        props: true
      },
    ],
  },
  {
    path: '/groups/:groupId/schedules/:scheduleId',
    name: 'Schedule',
    props: true,
    component: () => import('../views/Schedule.vue'),
    children: [
      {
        path: 'variants/:variantIdx',
        name: 'ScheduleVariant',
        component: () => import('../views/ScheduleVariant.vue'),
        props: true
      },
    ],
  },
  {
    path: '/locations',
    name: 'Locations',
    component: () => import('../views/Locations.vue'),
  },
  {
    path: '/locations/:locationId',
    name: 'Location',
    component: () => import('../views/Location.vue'),
    redirect: { name: 'LocationCourses' },
    children: [
      {
        path: 'courses',
        name: 'LocationCourses',
        component: () => import('../views/LocationCourses.vue'),
        children: [
          {
            path: 'manage/:courseId',
            name: 'ManageCourse',
            component: () => import('../views/LocationManageCourse.vue'),
          }]
      },
      {
        path: 'devices',
        name: 'LocationDevices',
        component: () => import('../views/LocationDevices.vue'),
      },
      {
        path: 'personnel',
        name: 'LocationPersonnel',
        component: () => import('../views/LocationPersonnel.vue'),
        children: [
          {
            path: 'manage/:userId',
            name: 'ManageUser',
            component: () => import('../views/LocationManageUser.vue'),
          }]
      },
      {
        path: 'plan-categories',
        name: 'LocationPlanCategories',
        component: () => import('../views/LocationPlanCategories.vue'),
        props: true,
      },
      {
        path: 'edex-import',
        name: 'LocationEdexImport',
        component: () => import('../views/LocationEdexImport.vue'),
      },
    ],
  },
  {
    path: '/groups/:groupId/posts/:kind/new',
    name: 'NewPost',
    component: () => import('../views/NewPost.vue'),
    props: true,
  },
  {
    path: '/groups/:groupId/posts/:kind/:postId/edit',
    name: 'EditPost',
    component: () => import('../views/NewPost.vue'),
    props: true,
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/Error.vue'),
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('../views/Unauthorized.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
})

export default router
