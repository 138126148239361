<template>
  <span
    class="absolute right-6 top-5 flex rounded-full border p-1 text-xs [&>span]:cursor-pointer [&>span]:rounded-full [&>span]:px-3 [&>span]:py-1"
  >
    <span
      :class="{ 'bg-pink-900 text-white': !archived }"
      @click="archived = false"
      >Actief</span
    >
    <span
      :class="{ 'bg-pink-900 text-white': archived }"
      @click="archived = true"
      >Archief</span
    >
  </span>
  <default-block>
    <template #title>Groepen</template>

    <div class="space-y-4">
      <div class="flex items-center gap-2">
        <new-group
          :location-id="locationId"
          @add:group="refreshGroups"
          class="grow"
        />
      </div>

      <card-container v-if="data && sortedGroups.length">
        <default-card
          v-for="group in sortedGroups"
          :key="group.id"
          :action-route="{
            name: 'Group',
            params: { groupId: group.id as string },
          }"
          action-title="Ga naar groep"
        >
          <template #title>Groep {{ group.name }}</template>
          <div class="flex flex-wrap gap-1">
            <user-icon
              v-for="teacher in group.teachers"
              :key="teacher.id"
              :user="teacher"
              :title="teacher.name"
            />
          </div>
        </default-card>
      </card-container>

      <p v-else>Deze school heeft (nog) geen groepen.</p>
    </div>
  </default-block>
</template>

<script setup lang="ts">
  import { computed, reactive, ref } from 'vue'
  import { useQuery } from 'villus'
  import { useHead } from '@vueuse/head'

  import { CheckIcon } from '@heroicons/vue/20/solid'

  import DefaultBlock from '../components/DefaultBlock.vue'
  import DefaultCard from '../components/DefaultCard.vue'
  import CardContainer from '../components/CardContainer.vue'
  import UserIcon from '../components/UserIcon.vue'
  import NewGroup from '../components/NewGroup.vue'

  import query from '../graphql/Groups.graphql'
  import { sortGroups } from '../mixins'

  useHead({
    title: 'Groepen',
  })

  const props = defineProps({
    currentLocation: {
      type: Object,
      required: true,
    },
  })

  const emit = defineEmits(['set:flash'])

  const archived = ref(false)
  const locationId = computed(() => props.currentLocation.id)
  const variables = reactive({ locationId: locationId, archived })

  const { data, execute, isDone } = useQuery({ query, variables })

  const sortedGroups = computed(() => {
    if (isDone.value) {
      return sortGroups(data.value.groups as Group[])
    } else {
      return []
    }
  })

  const refreshGroups = () => {
    execute()
    emit('set:flash', { message: 'Groep toegevoegd' })
  }
</script>

<style scoped>
  .teachers span:after {
    content: ', ';
  }
  .teachers span:last-child:after {
    content: '';
  }
</style>
