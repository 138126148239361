<template>
  <form @submit.prevent="createGroup">
    <p class="flex gap-2">
      <input
        v-model.trim="name"
        type="text"
        placeholder="Nieuwe groep"
        required
        class="grow"
      />
      <button>Toevoegen</button>
    </p>
  </form>
</template>

<script setup lang="ts">
  import { useMutation } from 'villus'
  import { ref } from 'vue'
  import CreateGroup from '../graphql/CreateGroup.graphql'

  const name = ref('')
  const { execute } = useMutation(CreateGroup)

  const emit = defineEmits(['add:group'])
  const props = defineProps({
    locationId: {
      type: String,
      required: true,
    },
  })

  const createGroup = () => {
    const trimmedName = name.value.replace('Groep ', '').trim()
    execute({ name: trimmedName, locationId: props.locationId }).then(
      ({ data }) => {
        emit('add:group', data.createGroup)
        name.value = ''
      },
    )
  }
</script>
