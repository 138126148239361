function sortGroups(groups: Group[]) {
  return groups.sort((a, b) => {
    const aSort = (a.name.match(/[0-9]/) || [])[0] || a.name
    const bSort = (b.name.match(/[0-9]/) || [])[0] || b.name

    return aSort > bSort ? 1 : -1
  })
}

export { sortGroups }

